<template>
  <v-container class="mt-10 my-2 mx-2 px-3">
    <div class="text-center">
      <div v-if="!$store.getters.isLoggedIn">
        <v-alert border="left" color="red" dense prominent type="error">
          <h2>
            If you want to obtain the numbers of Moroccan girls, please
            <router-link
              :to="{
                name: 'Login',
                query: { nextPath: 'Number' },
              }"
              class="black--text"
            >
              login
            </router-link>
            Or
            <router-link to="/register"> register a new account</router-link>
          </h2>
          <v-divider>s</v-divider>
          <h2>
            إن أردت الحصول على أرقام بنات مغربيات المرجو
            <router-link
              :to="{
                name: 'Login',
                query: { nextPath: 'Number' },
              }"
              class="black--text"
              >تسجيل الدخول</router-link
            >
            أو <router-link to="/register">تسجيل حساب جديد</router-link>
          </h2>
          <router-link
            :to="{
              name: 'Login',
              query: { nextPath: 'Number' },
            }"
          >
            <v-btn class="ma-2" rounded color="success">
              <span>Login Account</span>
            </v-btn>
          </router-link>
        </v-alert>
      </div>
      <div v-else>
        <div v-if="nodata">
          <v-alert border="left" color="red" dense prominent type="error">
            <p>
              You have finished 5 try today to get more numbers, please try
              tomorrow
            </p>
            <p>
              لقد انتهت 5 محاولة اليوم للحصول على أرقام أخرى المرجو المحاولة غدا
            </p>
          </v-alert>
        </div>
        <div v-else-if="loadingData">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-heading, list-item-two-line, table-tfoot"
          ></v-skeleton-loader>
        </div>
        <div v-else>
          <h1><v-icon>mdi-account</v-icon> {{ fullname }}</h1>
          <v-card class="pa-3">
            <v-col cols="12" sm="6" md="6" class="inputs">
              <v-text-field
                label="first name"
                placeholder="first name"
                filled
                rounded
                v-model="first_name"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="inputs">
              <v-text-field
                label="last name"
                placeholder="last name"
                filled
                rounded
                v-model="last_name"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="inputs">
              <v-text-field
                label="city "
                placeholder="city"
                filled
                rounded
                v-model="city"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="inputs">
              <v-text-field
                label="gendar "
                placeholder="gendar"
                filled
                rounded
                v-model="gendar"
                readonly
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" class="inputs">
              <v-text-field
                label="birthday "
                placeholder="birthday"
                filled
                rounded
                v-model="birthday"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="inputs">
              <v-text-field
                label="idusers "
                placeholder="idusers"
                filled
                rounded
                v-model="idusers"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="inputs">
              <v-text-field
                label="link "
                placeholder="link"
                filled
                rounded
                v-model="link"
                readonly
                dense
              ></v-text-field>
            </v-col>

            <v-row>
              <a
                :href="`https://api.whatsapp.com/send?phone=${number}`"
                target="_blank"
                class="ma-3"
                rel="noopener noreferrer"
              >
                <v-btn
                  color="green"
                  depressed
                  elevation="2"
                  large
                  medium
                  rounded
                  small
                >
                  whatsapp <v-icon>mdi-whatsapp</v-icon></v-btn
                >
              </a>

              <a
                :href="link"
                target="_blank"
                class="ma-3"
                rel="noopener noreferrer"
              >
                <v-btn
                  color="blue"
                  depressed
                  elevation="2"
                  large
                  medium
                  rounded
                  small
                >
                  Facebook <v-icon>mdi-facebook</v-icon></v-btn
                >
              </a>
            </v-row>
            <v-col>
              <v-btn
                class="ma-2"
                outlined
                rounded
                color="success"
                @click="getData"
              >
                <span>Get New Number</span>
                <v-icon>mdi-update</v-icon>
              </v-btn>
            </v-col>
          </v-card>
        </div>
        <v-divider> </v-divider>
        <v-card class="ma-3">
          <div v-if="nodataphone" class="col-md-8">
            <v-alert
              border="left"
              color="red"
              dense
              elevation="13"
              type="error"
            >
              ooops not number for you
              <v-divider></v-divider>
            </v-alert>
          </div>
          <div v-else-if="!dataPhone.length">
            <div class="ma-2" v-for="g in 5" :key="g">
              <v-flex xs12 md10 sm10 justify-center>
                <v-skeleton-loader
                  v-bind="attrs"
                  type="list-item-avatar-three-line"
                ></v-skeleton-loader>
              </v-flex>
            </div>
          </div>

          <div v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      id
                    </th>
                    <th class="text-center">
                      first name
                    </th>
                    <th class="text-center">
                      last name
                    </th>
                    <th class="text-center" v-if="memberadmin == 1">
                      phone
                    </th>

                    <th class="text-center">
                      time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, items) in dataPhone" :key="items">
                    <td class="text-center">{{ item.id }}</td>
                    <td class="text-center">
                      {{ item.phoneNumber.first_name }}
                    </td>
                    <td class="text-center">
                      {{ item.phoneNumber.last_name }}
                    </td>

                    <td class="text-center">{{ item.phoneNumber.phone }}</td>

                    <td class="text-center">
                      <timeago
                        class="float-center"
                        :datetime="item.createdAt"
                        :auto-update="60"
                      ></timeago>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      city: "",
      birthday: "",
      link: "",
      gendar: "",
      idusers: "",
      fullname: "",
      nodata: false,
      loadingData: true,
      number: "",
      dataPhone: [],
    };
  },
  methods: {
    getMyInfo: function() {
      this.loadingData = true;
      let AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      this.$http
        .get("/phone/mynumbers/" + this.$store.getters.getUserId, {
          headers: {
            Authorization: AuthStr,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            id: this.$store.getters.getUserId,
          },
        })
        .then((res) => {
          if (res.data.length != 0) {
            this.dataPhone = res.data;
          } else {
            this.nodataphone = true;
          }
        })
        .catch((err) => {
          this.nodataphone = true;
          return err;
        });
    },
    getData: function() {
      this.loadingData = true;
      let AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      this.$http
        .get("/phone/getday", {
          headers: {
            Authorization: AuthStr,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            id: this.$store.getters.getUserId,
          },
        })
        .then((res) => {
          this.getMyInfo();
          this.loadingData = false;
          if (res.data.length != 0) {
            this.first_name = res.data.first_name;
            this.last_name = res.data.last_name;
            this.city = res.data.city;
            this.birthday = res.data.birthday;
            this.link = res.data.link;
            this.gendar = res.data.gender;
            this.idusers = res.data.idusers;
            this.fullname = res.data.nameuser;
            this.number = res.data.phone;
          } else {
            this.nodata = true;
          }
        })
        .catch((err) => {
          this.getMyInfo();
          this.loadingData = false;
          this.nodata = true;
          return err;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.inputs {
  margin: 0;
  padding: 0;
}
</style>
